<template>
  <div id="companyVehicleForm" class="form">
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="companyVehicle.vehicleLicensePlate"
        label="License Plate"
        :required="true"
      />

      <v-select
        v-model="companyVehicle.vehicleType"
        :items="companyVehicle.relations.vehicleType"
        label="Type"
        chips
        deletable-chips
      ></v-select
      ><v-text-field
        v-model="companyVehicle.vehicleNotes"
        label="Notes"
        :required="false"
      />
      <v-autocomplete
        v-model="companyVehicle.companyId"
        :items="companyVehicle.relations.company"
        label="Company"
        :multiple="false"
        item-text="name"
        item-value="id"
        :chips="false"
        deletable-chips
      ></v-autocomplete>
      <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getCompanyVehicle } from "@/services/companyVehicle";

import { getAllCompanies } from "@/services/company";

import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      valid: false,
      companyVehicle: {
        relations: {
          vehicleType: ["TRUCK", "SEDAN", "SUV", "VAN"],
          company: [],
        },
        vehicleLicensePlate: "",
        vehicleType: "TRUCK",
        vehicleNotes: "",
        companyId: null,
      },
    };
  },
  created() {
    this.setInstance();
  },
  methods: {
    ...mapActions("companyVehicle", [
      "createCompanyVehicle",
      "updateCompanyVehicle",
    ]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async handleSubmit() {
      if (this.id) {
        let companyVehicle = this.companyVehicle;
        delete companyVehicle.relations;

        this.updateCompanyVehicle(companyVehicle)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("CompanyVehicle was updated successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating CompanyVehicle, please try again later"
            );
          });
      } else {
        let companyVehicle = this.companyVehicle;
        delete companyVehicle.relations;

        this.createCompanyVehicle(companyVehicle)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("CompanyVehicle was created successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating CompanyVehicle, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.id) {
        this.companyVehicle.id = this.id;
        getCompanyVehicle(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(
                this.companyVehicle,
                property
              )
            ) {
              this.companyVehicle[property] = instance[property];
            }
          }
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
      this.setDependencies();
    },
    setDependencies() {
      const params_company = {
        page: 0,
        size: 5000,
        sort: 'name'
      };
      getAllCompanies(params_company).then((response) => {
        this.companyVehicle.relations.company = response.data.content;
      });
    },
  },
  components: {},
};
</script>
